import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ResponsiveAppBarDocs from './ResponsiveAppBarDocs';
import './Docs.css';
import Spot from './Spot';
import Margin from './Margin';
import Futures from './Futures';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Docs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    
    <ResponsiveAppBarDocs/>
    <Box sx={{ width: '100%', height: '100%'}}>
       
      <Box sx={{ borderBottom: 5, borderColor: 'white' }} >
        <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab label="Spot" {...a11yProps(0)} />
          <Tab label="Futures" {...a11yProps(1)} />
          <Tab label="Margin" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}  >
      <Box
        sx={{
          height: '100%',
          width: '98%',
          display: 'inline-block',
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#101010' : '#7695C0',
          color: '#fff',
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '150',
          textAlign: 'center',
          
        }}
      >
      
      <Spot/>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Box
        sx={{
          height: '100%',
          width: '98%',
          display: 'inline-block',
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#101010' : '#7695C0',
          color: '#fff',
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '150',
          textAlign: 'center',
          
        }}
      >
      
      <Futures/>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Box
        sx={{
          height: '100%',
          width: '98%',
          display: 'inline-block',
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#101010' : '#7695C0',
          color: '#fff',
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '150',
          textAlign: 'center',
          
        }}
      >
      
      <Margin/>
      </Box>
      </TabPanel>
    </Box>
    </>
  );
}