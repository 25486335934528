import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import whalertLogo from '../whalert.png';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {  useNavigate } from "react-router-dom"
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const pages = ['Pricing', 'Dashboard', 'Support'];

function ResponsiveAppBarDocs() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    switch (event.currentTarget.value) {
      case 0:
        openInNewTab("https://whalert.io/?page_id=276");
        break;
      case '0':
        openInNewTab("https://whalert.io/?page_id=276");
        break;  
      case 1:
        navigate("/dashboard")
        break;
      case '1':
        navigate("/dashboard")
        break;
      case 2:
        openInNewTab("https://whalert.io/?page_id=303");
        break;
      case '2':
        openInNewTab("https://whalert.io/?page_id=303");
         break;

      default:
        break;
    }
  };



    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    switch (event.currentTarget.value) {
        case 0:
            //openInNewTab("https://twitter.com/WhalertOfficial");
            break;
        case 1:
            openInNewTab("https://t.me/whalertofficial")
            break;
        case 2:
            openInNewTab("https://www.youtube.com/channel/UCRgyzXKNP3vD3jMdkxIo2pg")
            break;
        default:
            break;
    }

  };

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#6088C1',
      },
      secondary: {
        main: '#ffff'
      }
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color='primary' enableColorOnDark>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar src= {whalertLogo}  sx={{ width: 50, height: 50, display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/Docs"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Poppins',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Whalert Docs
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem key={page} onClick={handleCloseNavMenu} value={i}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Avatar src= {whalertLogo}  sx={{ width: 50, height: 50, display: { xs: 'flex', md: 'none' }, mr: 1 }}/>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: '',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, i) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                value={i}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <ContactSupportIcon fontSize="large" color='secondary' />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

                <MenuItem  onClick={handleCloseUserMenu} value='1' >
                  <Typography textAlign="center" ><TelegramIcon/></Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseUserMenu} value='2' >
                  <Typography textAlign="center" ><YouTubeIcon/></Typography>
                </MenuItem>
                
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </ThemeProvider>
  );
}
export default ResponsiveAppBarDocs;