import React, { useState, useEffect  } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useAuth } from "./contexts/AuthContext"
import { doc, getDoc} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export default function DataTable() {

  const { currentUser } = useAuth()

  const [rows, setrows] = useState([])
  const [open, setOpen] = useState(true)
  

  useEffect(() => {
    const db = getFirestore()
    const docRef = doc(db, 'logs', currentUser.email);
    //CONSEGUIR DATOS DE USARIO
    const fetch = async () => {
      
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const aux = docSnap.data().logs
            aux.map ((element) => { 
              const date = new Date(element.date);
              element.api = '**********'+element.api.slice(10)
              const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
              element.date = date.toLocaleDateString(undefined, options)
              return null
            })

            
            setOpen(false)
            setrows(aux.reverse())
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document logs!");
            setOpen(false)
            setrows([{id: "No logs" }])
          }
    }
    fetch()
}, [currentUser])

const columns = [
  { id: 'id', label: 'ID Log', minWidth: 170 },
  {
    id: 'date',
    label: 'Timestamp',
    minWidth: 170,
    align: 'left'
    
  },
  {
    id: 'api',
    label: 'Whalert API',
    minWidth: 170,
    align: 'left',
    format: (value) => value,
  },
  {
    id: 'body',
    label: 'Data',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'symbol',
    label: 'Pair',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toFixed(2),
  },  
  {
    id: 'quantity',
    label: 'Quantity',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toFixed(7),
  },  
  {
    id: 'price',
    label: 'Price',
    minWidth: 170,
    align: 'left',
  },
];


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <> 
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
    
    {rows && 
    <Paper sx={{ width: '100%', height: '100%' , overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700, pl:2 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>}
    </>
  );
}