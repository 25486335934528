import React, {  useRef, useState } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import whalertLogo from './whalert.png';
import { useAuth } from "./contexts/AuthContext"
import Alert from '@mui/material/Alert';
import HCaptcha from '@hcaptcha/react-hcaptcha';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://whalert.io/">
        Whalert.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgotPassword() {

  const [error, setError] = useState("")
  const { resetPassword } = useAuth()
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    captchaRef.current.execute();
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
        setError("")
      //   setLoading(true)
        const resp = await resetPassword(data.get('email'))
        console.log(resp)
        setError("A recovery email has been sent to "+data.get('email'))
      } catch (err) { 
        setError("A recovery email has been sent to "+data.get('email'))
      }
};

  

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar src= {whalertLogo}  sx={{ width: 150, height: 150 }}/>

          <Typography component="h1" variant="h5">
             Recover password
          </Typography>

          {error && <Alert severity="success">{error}</Alert>}

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />



            <HCaptcha
              sitekey="0261842b-ea74-4bc0-a52d-09197787ebd4"
              onLoad={onLoad}
              onVerify={setToken}
              ref={captchaRef}
            />
                  
                {token && 
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Send mail
                  </Button>
                }
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2">
                Do you have an account?
                </Link>
              </Grid>

            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}