import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Grid from '@mui/material/Grid';

export default function Margin() {

const codeStringSpotBuy = `{"api": "YOUR API WHALERT",
"destiny": "margin",
"pair": "BTCBUSD",
"side": "buy",
"type": "market",
"quantity": 0.01}`;

const codeStringSpotSell = `{"api": "YOUR API WHALERT",
"destiny": "margin",
"pair": "BTCBUSD",
"side": "sell",
"type": "market",
"quantity": 0.01}`;

const codeStringLimitBuy = `{"api": "YOUR API WHALERT",
"destiny": "margin",
"pair": "BTCBUSD",
"side": "buy",
"type": "limit",
"quantity": 0.01,
"price": 20000}`;
const codeStringLimitSell = `{"api": "YOUR API WHALERT",
"destiny": "margin",
"pair": "BTCBUSD",
"side": "sell",
"type": "limit",
"quantity": 0.01,
"price": 35000}`;

const codeStringLimitOrder = `{"api": "YOUR API WHALERT",
"destiny": "margin",
"type":"stop_limit",
"pair": "BTCBUSD",
"side": "buy",
"quantity": 0.01,
"price": "16000",
"trigger": "17500"}`;

const codeStringLimitOrderSell = `{"api": "YOUR API WHALERT",
"destiny": "margin",
"type":"stop_limit",
"pair": "BTCBUSD",
"side": "sell",
"quantity": 0.01,
"price": "16100",
"trigger": "16000"}`;

const codeStringOCOBuy =`{"api": "YOUR API WHALERT",
"destiny": "margin",
"type":"OCO",
"pair": "BTCBUSD",
"side": "buy",
"quantity": 0.01,
"price": "16000",
"trigger": "18000",
"newprice": "17500"}`

const codeStringOCOSell =`{"api": "YOUR API WHALERT",
"destiny": "margin",
"type":"OCO",
"pair": "BTCBUSD",
"side": "sell",
"quantity": 0.01,
"price": "18000",
"trigger": "16000",
"newprice": "16500"}`

    const web = 'https://api.whalert.io/webhook'
  return (
    <Box sx={{ width: '100%', mt: 3
  
    }}>

      <Typography variant="h4" gutterBottom>
        Margin trading examples.
      </Typography>



      <Typography variant="subtitle2" gutterBottom>
      Remember to enter your binance API in the slots of your Whalert account, before setting up your alert in Tradingview with the corresponding command and the webhook.
      
      </Typography>
      <SyntaxHighlighter language="JSON" style={dark}>
                {web}
                </SyntaxHighlighter>


      <Grid container   >
      <Grid item  xs={12} sm={6} md={6}>
      <Box sx={{ textAlign: 'left', ml: 2, mr: 2}}>
            <Typography variant="h7" gutterBottom > 
            <SyntaxHighlighter language="java" style={dark}>
            BUY at market price 0.01 BTC with BUSD
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a market type buy order is sent to Binance, which means that it will take the best price from the order book at the moment to fill your order.
            </Typography>
      </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringSpotBuy}
                </SyntaxHighlighter>
            </Box>
        </Grid>
        <Grid item  xs={12} sm={6} md={6}>
          <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
                <Typography variant="h7" gutterBottom sx={{mt:2}}> 
                
                <SyntaxHighlighter language="java" style={dark}>
                SELL at market price 0.01 BTC and get BUSD
            </SyntaxHighlighter>
                </Typography>
                <Typography variant="body1" gutterBottom >
                With the following code, a market type sell order is sent to Binance, which means that it will take the best price from the order book at the moment to fill your order.
                </Typography>
          </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Box sx={{mr:2, ml:2}}>
                    <SyntaxHighlighter language="JSON" style={dark}>
                    {codeStringSpotSell}
                    </SyntaxHighlighter>
                </Box>
            </Grid>
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        <SyntaxHighlighter language="java" style={dark}>
        BUY at 0.01 BTC at 20,000 BUSD (Limit Order)
            </SyntaxHighlighter>
        
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a buy limit order is sent to Binance, which means that an order will be created on the order book and wait for it to fill.
If you enter a price limit higher than the current price, it is instantly filled as a market order.
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitBuy}
                </SyntaxHighlighter>
            </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        SELL at 0.01 BTC at 35,000 BUSD (Limit Order)
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a sell limit order is sent to Binance, which means that an order will be created on the order book and wait for it to fill.
If you enter a price limit lower than the current price, it is instantly filled as a market order.
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitSell}
                </SyntaxHighlighter>
            </Box>
        </Grid> 
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        {`BUY 0.01 BTC at 16,000 price (limit order) if the price >= 17,500 (Stop-Limit Order)`}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            If BTC price is {'>='} 17,500, a limit order will be created to buy 0.01 BTC at 16,100. 
            <br/>
            <br/>
            TIP: If you want to buy 0.01 BTC at market price when the price {'>='} 17,500 just set a price higher than the trigger (if the trigger is 17,500, set 18,000 as price)
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitOrder}
                </SyntaxHighlighter>
            </Box>
        </Grid> 
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        {`SELL 0.01 BTC at 16,100 price (limit order) if the price <= 16,000 (Stop-Limit Order)`}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            If BTC price is {'<='} 16,000, a limit order will be created to sell 0.01 BTC at 16,100.
            <br/>
            <br/>
            TIP: If you want to sell 0.01 BTC at market price when the price {'<='} 16,000 just set a price lower than the trigger (if the trigger is 16,000, set 15,000 as price)
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitOrderSell}
                </SyntaxHighlighter>
            </Box>
        </Grid> 
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        {`OCO Order Buy 0.01 BTC at 16,000`}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            One cancels the other, the one that is executed first eliminates the other order.
            <br/>
            Order limit is created to BUY 0.01 BTC at a price of 16,000, but if the price reaches {">="} 18,000 cancels the order created before and creates a new order limit to BUY 0.01 BTC at a price of 17,500. 
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringOCOBuy}
                </SyntaxHighlighter>
            </Box>
        </Grid> 
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        {`OCO Order Sell 0.01 BTC at 18,000`}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            One cancels the other, the one that is executed first eliminates the other order.
            <br/>
            Order limit is created to SELL 0.01 BTC at a price of 18,000, but if the price drops {"<="} 16,000 cancels the order created before and creates a new order limit to BUY 0.01 BTC at a price of 16,500. 
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringOCOSell}
                </SyntaxHighlighter>
            </Box>
        </Grid> 




      </Grid>
      <Typography variant="overline" display="block" gutterBottom>
      If there is a connection error to your binance API, you will see it in the Logs section, in your Whalert control panel.
      </Typography>
    </Box>
  );
}