import React from 'react'
import Chip from '@mui/material/Chip';
import Icon from "react-crypto-icons";

            

export default function Balance ({data = null}) {

  return (
    <>

    {
    
    data.map((element, i) => {
        return(
          <>
            
          <Chip icon={<Icon name={element.asset.toLowerCase()} size={25} />} label={`${element.asset} ${parseFloat(element.walletBalance)+( parseFloat(element.unrealizedProfit) )} `} variant="outlined" key={i} sx={{ml: 1, mb: 1}} color="primary"/>
          </>
            
        )
     })
    
    }
    </>
  )
}
