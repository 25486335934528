import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

export default function Futures() {

const codeStringFuturesBuyOWM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"market",
"pair": "BTCBUSD",
"side": "buy",
"quantity": "qty,0.01"}`;

const codeLeverage = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"market",
"pair": "BTCBUSD",
"leverage" : 20,
"side": "buy",
"quantity": "qty,0.01"}`;

const codeStringFuturesSellOWM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"market",
"pair": "BTCBUSD",
"side": "sell",
"quantity": "percent,10"}`;

const codeStringLimitBuyOWM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"limit",
"pair": "BTCBUSD",
"side": "buy",
"price": 15000.1,
"quantity": "qty,0.01"}`;

const codeStringLimitSellOWM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"limit",
"pair": "BTCBUSD",
"side": "sell",
"price": 35000.3,
"quantity": "qty,0.01"}`;

const codeStringStopLimitOWM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "stop_limit",
"pair": "BTCBUSD",
"side": "buy",
"price": 17500,
"stop": 18000,
"quantity": "qty,0.01"}`;

////////////////////////////////////////////////////////////////////////////

const codeStringFuturesBuyHM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"market",
"pair": "BTCBUSD",
"side": "buy",
"hedgeMode": "long",
"quantity": "qty,0.01"}`;

const codeStringFuturesSellHM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"market",
"pair": "BTCBUSD",
"side": "sell",
"hedgeMode": "short",
"quantity": "qty,0.01"}`;

const codeStringLimitBuyHM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"limit",
"pair": "BTCBUSD",
"side": "buy",
"price": 15000,
"hedgeMode": "long",
"quantity": "qty,0.01"}`;

const codeStringLimitSellHM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type":"limit",
"pair": "BTCBUSD",
"side": "sell",
"price": 20000,
"hedgeMode": "short",
"quantity": "percent,10"}`;

const reduceLong = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type" : "market",
"pair": "BTCBUSD",
"side": "sell",
"hedgeMode": "long",
"quantity": "qty,0.01"}`;

const reduceShort = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "limit",
"pair": "BTCBUSD",
"side": "buy",
"price": 15000,
"hedgeMode": "short",
"quantity": "qty,0.01"}`;



const codeStringLimitOrderHM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "stop_limit",
"pair": "BTCBUSD",
"side": "buy",
"price": 18000,
"stop": 17500,
"hedgeMode": "long",
"quantity": "qty,0.01"}`;

const codeStringLimitOrderSellHM = `{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "stop_limit",
"pair": "BTCBUSD",
"side": "sell",
"price": 14500,
"stop": 15000,
"hedgeMode": "long",
"quantity": "qty,0.01"}`;

const codeStringTPSLHM =`{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "tpsl",
"pair": "BTCBUSD",
"side": "buy",
"quantity": "qty,0.01",
"sl": "price,16000",
"tp": "price,19000",
"hedgeMode": "long"}`

const codeStringTPSLOWH =`{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "tpsl",
"pair": "BTCBUSD",
"side": "buy",
"quantity": "qty,0.01",
"tp": "price,19000"}`

const codeMultipleCommands =`{"api": "YOUR API WHALERT",
"commands" : {
"1": {
"destiny": "futures",
"type": "limit",
"pair": "BTCBUSD",
"side": "buy",
"price": "15000",
"quantity": "percent,50",
"hedgeMode": "long",
"leverage": 10},
"2": {
"destiny": "futures",
"type": "limit",
"pair": "BTCUSDT",
"side": "sell",
"price": "35000",
"quantity": "percent,100",
"hedgeMode": "short",
"leverage": 20}}}`

const codeCancel =`{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "cancel",
"pair": "BTCUSDT"}`

const codeClose =`{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "close",
"pair": "MATICBUSD",
"qtyReduce": "percent,20"}`

const codeCloseWithQty =`{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "close",
"pair": "MATICBUSD",
"qtyReduce": "qty,20"}`


const codeCloseFully =`{"api": "YOUR API WHALERT",
"destiny": "futures",
"type": "close",
"pair": "BTCUSDT"}`

    const web = 'https://api.whalert.io/webhook'
  return (
    <Box sx={{ width: '100%', mt: 3
  
    }}>

      <Typography variant="h4" gutterBottom>
        Futures trading examples.
      </Typography>



      <Typography variant="subtitle2" gutterBottom>
      Remember to enter your binance API in the slots of your Whalert account, before setting up your alert in Tradingview with the corresponding command and the webhook.
      
      </Typography>
      <SyntaxHighlighter language="JSON" style={dark}>
                {web}
                </SyntaxHighlighter>


      <Grid container   >
      <Grid item xs={12} sm={12} md={12}>
        <Divider>        
          <Typography variant="h5" gutterBottom>
          One-Way Mode
          </Typography>
          </Divider>
        <Typography variant="body2" display="block" gutterBottom sx={{mt:1, textAlign: 'center', ml: 4, mr: 4}}>
        In One-way Mode, you can only hold positions in one direction under one contract.
        This configuration must be done in your binance account for more information visit <br/> 
        <Link href="https://bit.ly/types-of-position-modes " rel="noreferrer" target="_blank" variant="body2" color="inherit">
        {'Types of positions modes'}
        </Link>
        
        </Typography>
        </Grid>

        <Grid item  xs={12} sm={6} md={6}>
      <Box sx={{ textAlign: 'left', ml: 2, mr: 2}}>
            <Typography variant="h7" gutterBottom > 
            <SyntaxHighlighter language="java" style={dark}>
            |Market Order| LONG at market price 0.01 BTC, BTCBUSD PAIR
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a market type LONG order is sent to Binance, which means that it will take the best price from the order book at the moment to fill your order.
            </Typography>
      </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringFuturesBuyOWM}
                </SyntaxHighlighter>
            </Box>
        </Grid>

        <Grid item  xs={12} sm={6} md={6}>
      <Box sx={{ textAlign: 'left', ml: 2, mr: 2}}>
            <Typography variant="h7" gutterBottom > 
            <SyntaxHighlighter language="java" style={dark}>
            |Leverage| LONG at market price 0.01 BTC, BTCBUSD PAIR
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            You can use the "leverage" flag with the "market", "limit", "tpsl" commands to adjust the leverage on the pair.
            </Typography>
      </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeLeverage}
                </SyntaxHighlighter>
            </Box>
        </Grid>

        <Grid item  xs={12} sm={6} md={6}>
          <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
                <Typography variant="h7" gutterBottom sx={{mt:2}}> 
                
                <SyntaxHighlighter language="java" style={dark}>
                |Market Order WITH PERCENT OF BALANCE| SHORT BTC at market price with 10% of total BUSD AVAILABLE, BTCBUSD PAIR
            </SyntaxHighlighter>
                </Typography>
                <Typography variant="body1" gutterBottom >
                With the following code, a market type SHORT order is sent to Binance, which means that it will take the best price from the order book at the moment to fill your order.
                </Typography>
          </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Box sx={{mr:2, ml:2}}>
                    <SyntaxHighlighter language="JSON" style={dark}>
                    {codeStringFuturesSellOWM}
                    </SyntaxHighlighter>
                </Box>
            </Grid>
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        <SyntaxHighlighter language="java" style={dark}>
        |Limit Order| LONG 0.01 BTC at 15,000.1 BUSD
            </SyntaxHighlighter>
        
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a LONG  limit order is sent to Binance, which means that an order will be created on the order book and wait for it to fill.
            <br/>    If you enter a price limit higher than the current price, BINANCE will return an error, and you can see it in your logs in whalert.
            <br/>   If you have an active SHORT position and send a LONG, your SHORT position will be REDUCED. If you want to manage long and short positions simultaneously, take a look at the hedge mode section
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitBuyOWM }
                </SyntaxHighlighter>
            </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |Limit Order| SHORT 0.01 BTC at 35,000.3 BUSD
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a SHORT limit order is sent to Binance, which means that an order will be created on the order book and wait for it to fill.
            <br/>    If you enter a price limit lower than the current price, BINANCE will return an error, and you can see it in your logs in whalert.
            <br/>   If you have an active LONG position and send a SHORT, your LONG position will be REDUCED. If you want to manage long and short positions simultaneously, take a look at the hedge mode section
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitSellOWM}
                </SyntaxHighlighter>
            </Box>
        </Grid> 

        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        {`|Stop Limit| LONG 0.01 BTC at 17,500 price (limit order) if the price >= 18,000 `}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            If BTC price is {'>='} 18,000, a limit order will be created to LONG 0.01 BTC at 17,500. 
            <br/>
            <br/>
            TIP: If you want to buy 0.01 BTC at market price when the price {'>='} 18,000 just set a price higher than the STOP (if the STOP is 18,000, set 18,500 as price)
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringStopLimitOWM}
                </SyntaxHighlighter>
            </Box>
        </Grid> 





        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |Take Profit And Stop Loss| BUY at market price 0.01 BTC and set a TP at 19,000
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a  0.01 BTC market type LONG is sent to Binance, set a TP at 19,000 (You can send only SL or TP if you want, or both)
            <br/>
            TIP: you can use "percent" instead of "price" to calculate take profit or stop loss.
            </Typography>
        </Box>


        </Grid>


        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringTPSLOWH}
                </SyntaxHighlighter>
            </Box>
        </Grid> 






        <Grid item xs={12} sm={12} md={12}>
        <Divider>        
          <Typography variant="h5" gutterBottom>
          Hedge Mode
          </Typography>
          </Divider>
        <Typography variant="body2" display="block" gutterBottom sx={{mt:1, textAlign: 'center', ml: 4, mr: 4}}>
        In Hedge Mode, you can hold positions in both long and short directions simultaneously under the same contract.
        This configuration must be done in your binance account for more information visit <br/> 
        <Link href="https://bit.ly/types-of-position-modes " rel="noreferrer" target="_blank" variant="body2" color="inherit">
        {'Types of positions modes'}
        </Link>
        
        </Typography>
        </Grid>

        <Grid item  xs={12} sm={6} md={6}>
      <Box sx={{ textAlign: 'left', ml: 2, mr: 2}}>
            <Typography variant="h7" gutterBottom > 
            <SyntaxHighlighter language="java" style={dark}>
            |Market Order| LONG at market price 0.01 BTC, BTCBUSD PAIR
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a market type LONG order is sent to Binance, which means that it will take the best price from the order book at the moment to fill your order.
            </Typography>
      </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringFuturesBuyHM}
                </SyntaxHighlighter>
            </Box>
        </Grid>
        <Grid item  xs={12} sm={6} md={6}>
          <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
                <Typography variant="h7" gutterBottom sx={{mt:2}}> 
                
                <SyntaxHighlighter language="java" style={dark}>
                |Market Order| SHORT at market price 0.01 BTC, BTCBUSD PAIR
            </SyntaxHighlighter>
                </Typography>
                <Typography variant="body1" gutterBottom >
                With the following code, a market type SHORT order is sent to Binance, which means that it will take the best price from the order book at the moment to fill your order.
                </Typography>
          </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Box sx={{mr:2, ml:2}}>
                    <SyntaxHighlighter language="JSON" style={dark}>
                    {codeStringFuturesSellHM}
                    </SyntaxHighlighter>
                </Box>
            </Grid>
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        <SyntaxHighlighter language="java" style={dark}>
        |Limit Order| LONG 0.01 BTC at 15,000 BUSD
            </SyntaxHighlighter>
        
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a LONG  limit order is sent to Binance, which means that an order will be created on the order book and wait for it to fill.
            <br/>    If you enter a price limit higher than the current price, BINANCE will return an error, and you can see it in your logs in whalert.
            <br/>   If you submit a sell order to the LONG section of the hedge mode, it will not be handled as a SHORT position, it will be handled as a reduction of the current LONG position.
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitBuyHM }
                </SyntaxHighlighter>
            </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |Limit Order| SHORT BTC at 20,000 BUSD with a 10% total of balance AVAILABLE BUSD
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a SHORT limit order is sent to Binance, which means that an order will be created on the order book and wait for it to fill.
            <br/>    If you enter a price limit lower than the current price, BINANCE will return an error, and you can see it in your logs in whalert.
            <br/>   If you submit a buy order to the SHORT section of the hedge mode, it will not be handled as a LONG position, it will be handled as a reduction of the current SHORT position.
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitSellHM}
                </SyntaxHighlighter>
            </Box>
        </Grid> 

        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |REDUCE Only Market| REDUCE 0.01 BTC from the a current LONG position of 0.02 BTC, at Market price
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, REDUCE 0.01 BTC from a LONG position at market price.
            <br/>
            You can use REDUCE Only to have different take profit targets.
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {reduceLong}
                </SyntaxHighlighter>
            </Box>
        </Grid> 

        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |REDUCE Only Limit| REDUCE 0.01 BTC from the a current SHORT position of 0.02 BTC, at Limit price
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, REDUCE 0.01 BTC from a LONG position at 15,000 Limit price.
            <br/>
            You can use reduce only to have different take profit targets.
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {reduceShort}
                </SyntaxHighlighter>
            </Box>
        </Grid> 

        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        {`|Stop Limit| LONG 0.01 BTC at 17,500 price (limit order) if the price >= 18,000 `}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            If BTC price is {'>='} 18,000, a limit order will be created to LONG 0.01 BTC at 17,500. 
            <br/>
            <br/>
            TIP: If you want to buy 0.01 BTC at market price when the price {'>='} 18,000 just set a price higher than the STOP (if the STOP is 18,000, set 18,500 as price)
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitOrderHM}
                </SyntaxHighlighter>
            </Box>
        </Grid> 
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        {`|Stop Limit| SHORT 0.01 BTC at 14,500 price (limit order) if the price <= 15,000`}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            If BTC price is {'<='} 15,000, a limit order will be created to SHORT 0.01 BTC at 14,500 BUSD.
            <br/>
            <br/>
            For this example, the Stop Limit will generate a SHORT limit order which will reduce the LONG position in 0.01 BTC since we are in Hedge mode.
            </Typography>
        </Box>


        </Grid>
        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringLimitOrderSellHM}
                </SyntaxHighlighter>
            </Box>
        </Grid> 




        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |Take Profit And Stop Loss| BUY at market price 0.01 BTC and set a TP / SL
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, a  0.01 BTC market type LONG   is sent to Binance, set a TP at 19,000 and SL at 16,000 (You can send only SL or TP if you want)
            <br/>
            TIP: you can use "percent" instead of "price" to calculate take profit or stop loss.
            </Typography>
        </Box>


        </Grid>


        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeStringTPSLHM}
                </SyntaxHighlighter>
            </Box>
        </Grid> 

        <Grid item xs={12} sm={12} md={12}>
        <Divider>        
          <Typography variant="h5" gutterBottom>
          CANCEL orders and CLOSE Positions
          </Typography>
          </Divider>
          </Grid>


        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |Cancel pending orders| 
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            Cancel all pending orders in a pair.
            <br/>
            TIP: Canceled orders including limit, stop limit, take profit, and stop loss orders, cancel ALL pending orders on a specific pair.
            </Typography>
        </Box>


        </Grid>


        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeCancel}
                </SyntaxHighlighter>
            </Box>
        </Grid> 



        {/* CANCEL fully or partial*/}
        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |CLOSE a position partially| Reduces an active MATICBUSD position by 20%
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            If you want to close an order completely just enter a percentage of 100% or do not send the "qtyReduce" flag
            <br/>
            TIP: If you work with hedgeMode, when you send a close order command, it will be applied to both sides -LONG and SHORT-
            </Typography>
        </Box>


        </Grid>


        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeClose}
                </SyntaxHighlighter>
            </Box>
        </Grid> 

        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |CLOSE a position fully| CLOSE ANY position -SHORT AND LONG- in the PAIR BTCUSDT
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            If you want to close an order completely just enter a percentage of 100% or do not send the "qtyReduce" flag
            <br/>
            TIP: If you work with hedgeMode, when you send a close order command, it will be applied to both sides -LONG and SHORT-
            </Typography>
        </Box>


        </Grid>


        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeCloseFully}
                </SyntaxHighlighter>
            </Box>
        </Grid> 

        <Grid item xs={12} sm={6} md={6}>
        <Box sx={{mt:1, textAlign: 'left', ml: 2}}>
        <Typography variant="h7" gutterBottom  >
        
        <SyntaxHighlighter language="java" style={dark}>
        |CLOSE a position partially| Reduces an active MATICBUSD position by 20 MATIC
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            CLOSES a position by setting amount.
            <br/>
            TIP: If you work with hedgeMode, when you send a close order command, it will be applied to both sides -LONG and SHORT-
            </Typography>
        </Box>


        </Grid>


        <Grid item xs={12} sm={6} md={6} >
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeCloseWithQty}
                </SyntaxHighlighter>
            </Box>
        </Grid> 




        <Grid item xs={12} sm={12} md={12}>
        <Divider>        
          <Typography variant="h5" gutterBottom>
          Multiple commands in one alert
          </Typography>
          </Divider>
        <Typography variant="body2" display="block" gutterBottom sx={{mt:1, textAlign: 'center', ml: 4, mr: 4}}>
        In a json format, you can send multiple commands in one alert, check the example below <br/> 

        </Typography>
        </Grid>

        <Grid item  xs={12} sm={6} md={6}>
      <Box sx={{ textAlign: 'left', ml: 2, mr: 2}}>
            <Typography variant="h7" gutterBottom > 
            <SyntaxHighlighter language="java" style={dark}>
           {`|Limit Order| BTC LONG at 15,000 BUSD with a 50% total of balance AVAILABLE BUSD and set 10 as LEVERAGE
|Limit Order| BTC SHORT at 35,000 BUSD with a 100% total of balance AVAILABLE BUSD and set 20 as LEVERAGE `}
            </SyntaxHighlighter>
            </Typography>
            <Typography variant="body1" gutterBottom >
            With the following code, 2 commands are sent to binance with the established parameters.
            </Typography>
      </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
            <Box sx={{mr:2, ml:2}}>
                <SyntaxHighlighter language="JSON" style={dark}>
                {codeMultipleCommands}
                </SyntaxHighlighter>
            </Box>
        </Grid>






      </Grid>
      <Typography variant="overline" display="block" gutterBottom>
      If there is a connection error to your binance API, you will see it in the Logs section, in your Whalert control panel.
      </Typography>
    </Box>
  );
}