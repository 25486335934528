import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { AuthProvider } from "./contexts/AuthContext"
import PrivateRoute from './PrivateRoute';
import Docs from './Docs/Docs.js';
import Login from "./Login"
import Dashboard from "./Dashboard"
import ForgotPassword from "./ForgotPassword"
import Account from './Account';
import WhalertAPI from './WhalertAPI';
import TradeHistory from './TradeHistory';
import PositionsParent from './PositionsParent';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

function App() {


  return (
    <AuthProvider>
      <Router>
        <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route exact path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/Forgot-Password" element={<ForgotPassword/>} />
        <Route exact path="/docs" element={<Docs/>} />
        <Route exact path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
        <Route exact path="/api-slots" element={<PrivateRoute><WhalertAPI /></PrivateRoute>} />
        <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route exact path="/positions" element={<PrivateRoute><PositionsParent /></PrivateRoute>} />
        <Route exact path="/trades" element={<PrivateRoute><TradeHistory /></PrivateRoute>} />
        </Routes>
      
      </Router>
      </AuthProvider>
  );
}

export default App;
