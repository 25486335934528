import React, {  useState } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import whalertLogo from './whalert.png';
import { useAuth } from "./contexts/AuthContext"
import Alert from '@mui/material/Alert';
import HCaptcha from '@hcaptcha/react-hcaptcha';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://whalert.io/">
        Whalert.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const theme = createTheme();

export default function Login() {

    const { login } = useAuth()
    const [error, setError] = useState("")




    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

    try {
        setError("")
      //   setLoading(true)
        await login(data.get('email'), data.get('password'))
        window.open("https://members.whalert.io/dashboard","_self")
        
      } catch (err) { 
        setError("Wrong email or password")
      }
  };


  const [token, setToken] = useState(true);


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar src= {whalertLogo}  sx={{ width: 150, height: 150 }}/>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{ mb: 3 }}
            />


                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={token ? false : true}
                    >
                    Sign In
                    </Button>
                

            <Grid container>
              <Grid item xs>
                <Link href="/Forgot-Password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>

            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}