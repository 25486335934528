import React, { useState, useEffect  } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

export default function TradesTable({data = null}) {


  const [rows, setrows] = useState([])
  
    useEffect(() => {
      if (data.length === 0){
        setrows([{orderId: "There are no trades in the last 7 days"}])
        return
      }
      const aux = data
      aux.map ((element) => { 
        const date = new Date(element.time);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        element.time = date.toLocaleDateString(undefined, options)
        return null
      })

      setrows(aux.reverse())
    }, [data])

const columns = [
  { id: 'orderId', label: 'Order ID', minWidth: 170 },
  {
    id: 'time',
    label: 'Date',
    minWidth: 170,
    align: 'left'
    
  },
  {
    id: 'side',
    label: 'Side',
    minWidth: 50,
    align: 'left',
    format: (value) => value,
  },
  {
    id: 'symbol',
    label: 'Pair',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'executedQty',
    label: 'Executed Quantity',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toFixed(7),
  },  
  {
    id: 'type',
    label: 'Type',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'left',
  },
];


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <> 

    <Paper sx={{ width: '100%', height: '100%' , overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700, pl:2 }}>
        <Table stickyHeader aria-label="sticky table" component={'table'} >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))} 
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </>
  );
}