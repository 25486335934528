import React, { useState, useEffect  } from "react"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import  ResponsiveAppBar  from "./ResponsiveAppBar.js";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "./contexts/AuthContext"
import { doc, getDoc} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import ListTrades from "./ListTrades.js";


function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PositionsParent() {
  const [value, setValue] = React.useState(0);

  const [userData, setUserData] = useState(null)
  const { currentUser } = useAuth()

  useEffect(() => {
    const db = getFirestore()
    const docRef = doc(db, 'users', currentUser.email);
    //CONSEGUIR DATOS DE USARIO
    const fetch = async () => {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUserData(docSnap.data())
            
            setOpen(false)
          } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
            
            setOpen(false)
          }
    }
    fetch()
    
  }, [currentUser])

  //BACKDROP
  const [open, setOpen] = React.useState(true);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <ResponsiveAppBar/>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
    {!open && 
    <Box sx={{ width: '100%', height: '100%'}}>
       
    <Box sx={{  borderColor: 'white' }} display="flex" justifyContent="center" alignItems="center" >
      <Tabs  value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile>

        {
        userData.whalertAPI
        .map((element, i) => {
          return (
            <Tab key={element} label={`API Slot ${i+1}`} {...a11yProps(i)} />
          );
         })
        }
        
      </Tabs>
    </Box>
       {

      userData.whalertAPI
        .map((element, i) => {
          return (
            <TabPanel key={element} value={value} index={i}  >
            <ListTrades dataComponent={element} />
            </TabPanel>
          );
        })
       }
     </Box>
    }
            
        
      
    
    
    </>
  );
}

