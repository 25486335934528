import React, { useState, useEffect  } from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Chip from '@mui/material/Chip';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function PositionsTable({data = null}) {


  const [rows, setrows] = useState([])
  
    useEffect(() => {
      if (data.length === 0){
        setrows([{symbol: "There are no active positions"}])
        return
      }
      const aux = data
      aux.map ((element) => { 
        const date = new Date(element.updateTime);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        element.updateTime = date.toLocaleDateString(undefined, options)
        element.type = (parseFloat(element.positionAmt) < 0) ? "SHORT" : "LONG"
        element.positionAmt = Math.abs(parseFloat(element.positionAmt)) 
        
        
        return null
      })

      setrows(aux.reverse())
    }, [data])

const columns = [
  {
    id: 'symbol',
    label: 'Pair',
    minWidth: 100,
    align: 'left'
    
  },  
  {
    id: 'type',
    label: 'Type',
    minWidth: 50,
    align: 'left',
    format: (value) => value,
  },
  {
    id: 'positionAmt',
    label: 'Quantity',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toFixed(7),
  },  
  {
    id: 'entryPrice',
    label: 'Entry Price',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'positionInitialMargin',
    label: 'Position Initial Margin',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'unrealizedProfit',
    label: 'Unrealized Profit',
    minWidth: 170,
    align: 'left',
  },
];


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <> 

    <Paper sx={{ width: '100%', height: '100%' , overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700, pl:2 }}>
        <Table stickyHeader aria-label="sticky table" component={'table'} >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))} 
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>

                            {value === "SHORT" 
                            ? <Chip icon={<ArrowDownwardIcon color="error" />} label={`SHORT`} variant="outlined" sx={{ml: 1, mb: 1}} color="primary"/>
                            : value === "LONG"
                            ? <Chip icon={<ArrowUpwardIcon color="success" />} label={`LONG`} variant="outlined" sx={{ml: 1, mb: 1}} color="primary"/>
                            : column.id === "unrealizedProfit" && value !== undefined
                            ? value < 0 ? <Chip label={`${value}`} color="error" /> : <Chip label={`+${value}`} color="success" />
                            :column.format && typeof value === 'number'
                            ? column.format(value)
                            : value }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </>
  );
}