import React from "react"

import  ResponsiveAppBar  from "./ResponsiveAppBar.js";

import DataTable from "./DataTable";

function Dashboard() {
    


  return (
    <>

        <ResponsiveAppBar/>
        <DataTable/>

    </>
  )
}

export default Dashboard