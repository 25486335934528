import React, { useState, useEffect, useRef  } from "react"
import ReCAPTCHA from "react-google-recaptcha";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { doc, getDoc, updateDoc} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Wb from './img/wb.png';
import Alert from '@mui/material/Alert';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import SpeedIcon from '@mui/icons-material/Speed';
import SaveIcon from '@mui/icons-material/Save';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TelegramIcon from '@mui/icons-material/Telegram';
import KeyIcon from '@mui/icons-material/Key';
import PasswordIcon from '@mui/icons-material/Password';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://whalert.io/">
        Whalert.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function UpdateSlot({dataComponent = null}) {


  const captchaRef = useRef();
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [keyData, setKeyData] = useState(null)
  const [runningTest, setRunningTest] = useState(false)
  const [open, setOpen] = React.useState(true);

  const [connection, setConnection] = useState('0')
  const [connectionTelegram, setConnectionTelegram] = useState('0')
  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };


  const binanceTest = async () => {

    if (!captchaRef.current.getValue()) {
      setConnection('2')
      return
    }

    setRunningTest(true)

    axios
    .post('https://api.whalert.io/validate', {
      "api": keyData.pubKey,
      "secret": keyData.privKey,
      "g-recaptcha-response" : captchaRef.current.getValue(),
    })
    .then((res) => {
      console.log(res.data);
      if(res.data.code === 200){
        setConnection('1')
      }else{
        setConnection('2')
      }
      
    })
    .catch((err) => {
      console.log(err);
      setConnection('2')
    });

    captchaRef.current.reset()

    setRunningTest(false)

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let auxTelegramId = null
    let auxPub = null
    let auxSecret = null

    if(!data.get('telegramId')){
      if(keyData.telegramId){
        auxTelegramId = keyData.telegramId
      }else{
        auxTelegramId = "undefined"
      }
      
    }else{
      auxTelegramId = data.get('telegramId')
    }

    if(!data.get('secret')){
      auxSecret = keyData.privKey
    }else{
      auxSecret = data.get('secret')
    }

    if(!data.get('pub')){
      auxPub = keyData.pubKey
    }else{
      auxPub = data.get('pub')
    }


    try {
        
        setLoading(true)
        const db = getFirestore()

        const docRef = doc(db, "keys", dataComponent);

        // Set the "capital" field of the city 'DC'
        await updateDoc(docRef, {
          pubKey: auxPub,
          privKey: auxSecret,
          telegramId: auxTelegramId
        });


      window.location.reload(false)
      } catch (err) { 
        setError("Something went wrong",err)
      }
};


useEffect(() => {
  const db = getFirestore()
  const docRef = doc(db, 'keys', dataComponent);
  //CONSEGUIR DATOS DE USARIO
  const fetch = async () => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
          setKeyData(docSnap.data())
          
          setOpen(false)
        } else {
          // doc.data() will be undefined in this case
        }
    }
    fetch()
  }, [dataComponent])


//// TELEGRAM

const sendMessageTelegram = async (message, iduser) => {
  fetch("https://api.telegram.org/bot5937002343:AAFnUeT2_XSiBUhnnT-p-yWHgFB5228Ptaw/sendMessage?chat_id="+iduser+"&text="+message)
  .then(res => res.json())
  .then(
    (result) => {
        if (result.ok){
          setConnectionTelegram("Username: "+result.result.chat.username)
        }else{
          setConnectionTelegram(result.description)
        }
        
    },
    (error) => {
      setConnectionTelegram("Test failed")
      });
}


  
const handleTelegram = async () => {
  let flag = false
  if(keyData.telegram){
    flag = false
  }else{
    flag = true
  }


  try {
        
    setLoading(true)
    const db = getFirestore()

    const docRef = doc(db, "keys", dataComponent);

    // Set the "capital" field of the city 'DC'
    await updateDoc(docRef, {
      telegram: flag,
    });


  window.location.reload(false)
  } catch (err) { 
    setError("Something went wrong",err)
  }
}

  return (
    <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
    {!open && 
    
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Box
        component="img"
        sx={{
          height: 250,
          width: 440,
          maxHeight: { xs: 200, md: 200 },
          maxWidth: { xs: 350, md: 350 },
        }}
        alt="Link"
        src={Wb}
      />
      <Box sx={{ maxWidth: { xs: 300, md: 1000 } }} >
          <Typography component="span" variant="h7">
          <SyntaxHighlighter language="JSON" style={dark}>
                    {`API: ${dataComponent}`}
                    </SyntaxHighlighter>
          </Typography>
          </Box>

          {error && <Alert severity="error">{error}</Alert>}

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
         
            <Chip icon={<KeyIcon />} label="API Key" variant="outlined" />
            <TextField
              margin="normal"
              required
              fullWidth
              id="pub"
              label={`Current: ${keyData.pubKey}`}
              name="pub"
              autoComplete="null"
            />

            <Chip icon={<PasswordIcon />} label="Secret Key" variant="outlined" />
            <TextField
              margin="normal"
              required
              fullWidth
              id="secret"
              label={`Current: ${keyData.privKey}`}
              name="secret"
              autoComplete="null"
            />

            <Chip icon={<TelegramIcon />} label="Telegram ID" variant="outlined" />

            <IconButton aria-label="HelpIcon" color="info" size="large" sx={{}} onClick={handleClickOpen}>
              <HelpIcon fontSize="inherit" />
            </IconButton>

            <TextField
              margin="normal"
              required
              fullWidth
              id="telegramId"
              label={`Current: ${keyData.telegramId}`}
              name="telegramId"
              autoComplete="null"
            />
            {loading &&
            
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>

            }
              <Typography variant="caption" display="block" gutterBottom>
              Click on the button below to activate / deactivate Telegram notifications.
                </Typography>
            <Button variant="outlined" size="small" sx={{}} onClick={() => handleTelegram()} >
            Current status: {(keyData.telegram) ? 'On' : 'Off'} 
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SaveIcon />}
            >
              Save 
            </Button>

            <Divider />
            <Typography variant="caption" display="block" sx={{mt:1, mb:1}} gutterBottom>
            Solve the captcha before sending any test
            </Typography>
            <ReCAPTCHA sitekey="6Ldbsf4jAAAAAABtgj_WVh2n_aqNSnHK_oqmqKzb" ref={captchaRef} />
            
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SpeedIcon />}
              disabled={runningTest}
              onClick={() => binanceTest()}
            >
              Test Binance API
            </Button>

            <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip  label={connection === '1' ? "It works" : connection === '2' ? 'Error' : 'Run test'} color={connection === '1' ? "success" : connection === '2' ? 'error' : 'info'} variant="outlined"  icon={connection === '1' ? <CheckCircleIcon /> : connection === '2' ? <HighlightOffIcon /> : <SpeedIcon />} />
              
            </Stack>

          </Stack>

          <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SpeedIcon />}
              disabled={runningTest}
              onClick={() => sendMessageTelegram("This is a test notification. API Whalert: "+dataComponent, keyData.telegramId)}
            >
              Test Telegram Notification
            </Button>

            <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip   label={connectionTelegram === '0' ? 'Run test' : connectionTelegram} color={connectionTelegram === '0' ? 'primary' : 'info'} variant="outlined"  icon={connectionTelegram === '0' ? <SpeedIcon /> : <InfoIcon />} />

            </Stack>

          </Stack>

          
          </Box>
        </Box>



      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"How to activate notifications on Telegram?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          1.- Go to <Link href="https://t.me/whalert_notifications_bot" target="_blank">https://t.me/whalert_notifications_bot</Link> and press START in Telegram.<br/>
          2.- Go to <Link href="https://t.me/getmyid_bot" target="_blank">https://t.me/getmyid_bot</Link> and press START in Telegram, wait for the bot response, and copy Your user ID.<br/>
          3.- Paste your user ID obtained in step 2 in the corresponding slot in Whalert, save the data, activate the notifications, and run a test notification.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    }
    </>
  );
}