import React, { useState, useEffect, useRef  } from "react"
import Link from '@mui/material/Link';
import { doc, getDoc} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import TradesTable from "./TradesTable";
import ReCAPTCHA from "react-google-recaptcha";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://whalert.io/">
        Whalert.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ListTrades({dataComponent = null}) {

  const [getDataList, setGetDataList] = useState(null)


  const [error, setError] = useState("")
  const [open, setOpen] = React.useState(true);
  const reCaptchaRef = useRef();


useEffect(() => {
  const db = getFirestore()
  const docRef = doc(db, 'keys', dataComponent);
  //CONSEGUIR DATOS DE USARIO
  const fetch = async () => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {







        reCaptchaRef.current.execute().then(async (result) => {

          axios
          .post('https://api.whalert.io/balance', {
            "api": docSnap.data().pubKey,
            "secret": docSnap.data().privKey,
            "token": result
          })
          .then((result) => {
            
            if (result.data.code) {
              setError ("An error occurred, check your binance API or API SLOT is configured Code: "+result.data.code)
              setOpen(false)
            }else{
              setGetDataList(result.data)
            }
            setOpen(false)
          })
          .catch((err) => {
            console.log(error);
            setError ("An error occurred, check your binance API or API SLOT is configured or you sent too many requests in a short time.")
            setOpen(false)
          });
      
        }).catch((err) => {
            console.log(err);
            setError ("An error occurred, check your binance API or API SLOT is configured or you sent too many requests in a short time.")
            setOpen(false)
        });



        } else {
          // doc.data() will be undefined in this case
          setError ("An error occurred, check your binance API or API SLOT is configured")
          setOpen(false)
        }
    }
    fetch()
  }, [dataComponent, error])
  

  return (
    <>
           <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey="6Lfq2gckAAAAAKwgxyhJQgybeBhzkp3fB4JO3RFQ"
        size="invisible"
        />
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
        {error && <Alert severity="error">{error}</Alert>}
    {(!open && getDataList) && 
    
    <ThemeProvider theme={theme}>

        <TradesTable data={getDataList}  />
        
        <Copyright sx={{ mt: 8, mb: 4 }} />

    </ThemeProvider>

    }
    </>
  );
}