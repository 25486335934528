import React, { useState, useEffect  } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Whale from './img/whale.png';
import Fish from './img/fish.png';
import Dolphin from './img/dolphin.png';
import Shark from './img/shark.png';
import { useAuth } from "./contexts/AuthContext"
import Alert from '@mui/material/Alert';
import ResponsiveAppBar from "./ResponsiveAppBar";
import { doc, getDoc} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EmailIcon from '@mui/icons-material/Email';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://whalert.io/">
        Whalert.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Account() {

  const [error, setError] = useState("")
  const { resetPassword } = useAuth()
  const { currentUser } = useAuth()
  const [userData, setUserData] = useState(null)

  //BACKDROP
  const [open, setOpen] = React.useState(true);



  useEffect(() => {
      const db = getFirestore()
      const docRef = doc(db, 'users', currentUser.email);
      //CONSEGUIR DATOS DE USARIO
      const fetch = async () => {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
              setUserData(docSnap.data())
              setOpen(false)
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
      }
      fetch()
  }, [currentUser])

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        setError("")
      //   setLoading(true)
        await resetPassword(currentUser.email)
        console.log(currentUser.email)
        setError("A recovery email has been sent to "+currentUser.email)
      } catch (err) { 
        setError("A recovery email has been sent to "+currentUser.email)
      }
};

  const formatDate = (parm) => {
    const date = new Date(parm);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString(undefined, options)
  }

  return (
    <> {!open &&
      <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />

        </Backdrop>
    <ResponsiveAppBar/>
    
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Avatar src= {userData.product_id === 8024102207765 ? Dolphin : userData.product_id === 8024060264725 ? Fish : userData.product_id === 8024105419029 ? Shark : userData.product_id === 8024106926357 ? Whale : ""}  sx={{ width: 300, height: 300 }}/>
          
          <Box sx={{ alignItems: 'left' }} >
          {error && <Alert severity="success" sx={{mb:2}} >{error}</Alert>}
          <Typography component="h6" variant="h6">
             Email <EmailIcon/>: {userData.email}
          </Typography>
          <Typography component="h6" variant="h6">
             Plan <RotateLeftIcon/>: {userData.name}
          </Typography>
          <Typography component="h6" variant="h6">
             {userData.balance > 0 ? `Balance` : "Expiration Date "} : { userData.balance > 0 ? `$${userData.balance}` : formatDate(userData.expirationDate) }
          </Typography>
          </Box>




          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>


            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change password 
            </Button>
            <Grid container>


            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    </>
    }
    </>
  );
}